import React from "react"
import imgPortadaGuide from "@components/pageGuide/guides/guide90/image/imgPortadaGuia.png"
import img1 from "@components/pageGuide/guides/guide90/image/img.png"
import slider1 from "@components/pageGuide/guides/guide90/image/silder1.png"
import slider2 from "@components/pageGuide/guides/guide90/image/silder2.png"
import slider3 from "@components/pageGuide/guides/guide90/image/silder3.png"


const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"    >
      Política de Privacidad.
    </a>
  </span>
)

const data = {
  start: {
    support: "Guía | Inmobiliarias ",
    title: <h1 className="titles-underlined-ebook ebook-ventas">
      Cómo automatizar el cierre de ventas en proyectos de vivienda nueva
    </h1>,
    description: <p className="text-h2-ebook guide__description">
      La automatización ayuda a optimizar el cierre de ventas en proyectos de vivienda. Implementa herramientas para gestionar clientes y hacer seguimiento a cada oportunidad de venta. Usa plataformas para enviar mensajes programados y mantener el contacto con los interesados.
    </p>,
    classtext: "guide__description-container",
    link: linkForm,
    href: "/ebook-9.pdf",
    brochure: "Guía",
    img: imgPortadaGuide,
  },

  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Métodos para agilizar el proceso de cierre.",
      },
      {
        key: 2,
        text: "Uso de bots para seguimiento de clientes."
      },
      {
        key: 3,
        text: "Estrategias para personalizar la comunicación.",
      },
      {
        key: 4,
        text: "Implementación de recordatorios automatizados.",
      },
      {
        key: 5,
        text: "Análisis de datos para mejorar resultados.",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
    ],
  },
  description: {
    cards: [
      {
        text: <p>
          Recuerda que, con esta guía, podrás conocer cómo integrar procesos para responder consultas y agilizar negociaciones. Configura recordatorios para evitar perder oportunidades y mejorar la experiencia de compra.
          <br /><br />
          Por último, accede a más detalles, pasos prácticos y herramientas recomendadas descargándola. Optimiza el cierre de ventas con procesos automatizados.
        </p>,
        photo: img1,
        class: true,
      },
    ],
  },
}

export default data
