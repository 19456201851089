import React from "react"
import TemplatePageGuide from "@components/pageGuide/template"
import { Helmet } from "react-helmet"
import data from "@components/pageGuide/guides/guide90/data"

const IndexPage = ({ location }) => (
  <div className="fnd">
    <Helmet htmlAttributes={{ lang: 'es' }}>
      <title>
        Cómo automatizar el cierre de ventas en proyectos de vivienda nueva 
        [Antes Securitec]</title>
      <link
        rel="icon"
        type="image/png"
        href={`https://beexcc.com/Beex.png`}
      />
      <link
        rel="canonical"
        href={`https://beexcc.com/recursos/guias/proyectos-vivienda-nueva/`}
      />
      <meta name="robots" content="index, follow"></meta>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="Conoce cómo implementar estrategias para agilizar el cierre de ventas en proyectos de vivienda nueva mediante automatización y procesos eficientes."
      />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="Cómo automatizar el cierre de ventas en proyectos de vivienda nueva "
      />
      <meta
        property="og:url"
        content={`https://beexcc.com/recursos/guias/proyectos-vivienda-nueva/`}
      />
      <meta
        property="og:image"
        content={`https://beexcc.com/metaimagenRecursos/Guias/og-imgGuia90.jpg`}
      />
      <meta
        property="og:description"
        content="Conoce cómo implementar estrategias para agilizar el cierre de ventas en proyectos de vivienda nueva mediante automatización y procesos eficientes."
      />
    </Helmet>
    <TemplatePageGuide
      ebook="Inmobiliarias"
      location={location}
      typeGuide="guide90"
      clickHereHref="https://beexcc.com/blog/"
      data={data}
    />
  </div>
)

export default IndexPage
